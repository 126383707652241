import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default.js";
import Newsletter from "../components/Newsletter";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Subscribe to my newsletter`}</h1>
    <p>{`Hi! If you like what you see on my site, I have a newsletter that may be of interest. `}</p>
    <p>{`As part of The Wednesday Writeup, every week you will get a summary of what I have been working on, what I have been thinking about, and a number of recommendations for things to read and watch. I also include a few photos from somewhere I have traveled. `}</p>
    <p>{`You can subscribe to the newsletter or peruse the backlog at `}<a parentName="p" {...{
        "href": "https://aengus.substack.com"
      }}>{`aengus.substack.com`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      